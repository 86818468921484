import * as React from "react"
import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';

// markup
const Disclaimer = () => {
  return <Layout pageInfo={{ pageName: "disclaimer" }}>
    <Seo title="Disclaimer" />
  </Layout>
}

export default Disclaimer
